import req from "../methods/req"
import method from '../methods/method'

export const attendance = function ({
	_this,
	data
}){
    return method.get_list({
		_this,
        url:'/manage/crm.attendance/index',
        data
    })
}
// 获取考勤明细
export const getAttendanceList = function ({
	_this,
	data
}){
    return method.get_list({
		_this,
        url:'/manage/crm.attendance/getAttendanceList',
        data
    })
}

// 获取考勤规则数据
export const getRuleData = function (data){
    return req({
        url:'/manage/crm.attendance/getRuleData',
        data
    })
}

//获取用户列表
export const getUserList = function ({
	_this,
	data
}){
    return method.get_list({
		_this,
        url:'/manage/crm.common/getUserList',
        data
    })
}


// 添加编辑考勤规则数据
export const modify = function (data){
    return req({
        url:'/manage/crm.attendance/modify',
        ...data
    })
}
//获取用户列表
export const getSellerSelect = function ({
	_this,
	data
}){
    return method.get_list({
		_this,
        url:'/manage/crm.common/getSellerSelect',
        data
    })
}


