<template>
    <a-modal
        :title="form_data.title"
        :visible="visible"
        @ok="submit"
        :destroyOnClose="destroyOnClose"
        @cancel="handleCancel"
        :width="width"
		:okText="onText"
    >
		<!-- <slot name="okText"> </slot> -->
        <slot name="map"></slot>
        <Form
            ref="form"
            @handleSubmit="handleSubmit"
			@handleChange="handleChange"
            :form_data="form_data"
            @tree_select="tree_select"
			@get_select_value_tr='get_select_value_tr'
            @get_select_value="get_select_value"
        >
            <slot :slot="item.name" v-for="item in form_data.list" :name="item.name"></slot>
        </Form>
    </a-modal>
</template>

<script>
import Form from './Form'
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        width: {
            type: [String, Number],
            default: 520,
        },
		onText:{
            type: [String],
            default: '确定',
        },
        destroyOnClose: {
            type: Boolean,
            default: true,
        },
        form_data: {
            type: Object,
            default: () => {
                return {
                    list: []
                }
            }
        },
    },
    components: {
        Form,
    },
    beforeCreate() {
        // this.form = this.$form.createForm(this, { name: 'edit' });
    },
    data() {
        return {

        }
    },
    methods: {
        handleCancel() {
			  this.$emit('handleCancel');
            this.$emit('update:visible', false)
        },
        submit() {
            this.$refs.form.submit();
        },
        handleChange(e) {
            this.$emit('handleChange', e);
        },
		get_select_value_tr(e){
			 this.$emit('get_select_value_tr', e);
		},
        handleFocus(e) {
            this.$emit('handleFocus', e);
        },
        handleSubmit(e) {
            this.$emit('handleSubmit', e)
        },
        tree_select(e) {
            this.$emit('tree_select', e)
        },
        get_select_value(e) {
            this.$emit('get_select_value', e)
        },
        setFieldsValue(data) {
			console.log('data',data)
            this.$refs.form.setFieldsValue(data);
        },
    },

}
</script>
<style lang="less" scoped>
</style>
